// IIFE called when script is loaded
// Immediately invoked function expression
( function initModule() {
    const tiles = [ ...document.querySelectorAll( '.tile' ) ];

    if(!tiles) return;

    tiles.forEach( tile => {
        if( tile.dataset.target ) {
            const clickTarget =  tile.dataset.target;

            tile.addEventListener( 'click', ( event ) => {
                const targetIsTooltip = event.target.classList.contains('js-tooltip');
                const targetIsLink = event.target.tagName.toLowerCase() === 'a';

                if( !targetIsTooltip && !targetIsLink ) {
                    location.href = clickTarget;
                }

            } );
        }
    } );
}) ();
